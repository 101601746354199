import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface CoffeeShopFilter {
  filterByProperty: string;
  term: any;
}

@Injectable()
export class CoffeeShopFiltersService {

  public static DELIVERY_FILTER_PROPERTY = 'delivery_methods';
  public static NAME_FILTER_PROPERTY = 'name';

  public filters = new BehaviorSubject<CoffeeShopFilter[]>([]);

  setSearchCriteria(filterBy, term) {
    const filters = this.filters.value;
    const foundIndex = filters.findIndex((f) => {
      return f.filterByProperty === filterBy;
    });
    if (foundIndex > -1) {
      filters.splice(foundIndex, 1, {
        filterByProperty: filterBy,
        term: term
      });
    } else {
      filters.push({
        filterByProperty: filterBy,
        term: term
      });
    }
    this.filters.next([...filters]);
  }

}
