import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { Subscription } from 'rxjs';

import {CoffeeShopFiltersService, CoffeeShopFilter} from '../../../services/coffee-shop-filters.service';
import { ShopFilterModalComponent } from '../shop-filter-modal/shop-filter-modal.component';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-user-nav',
    templateUrl: './user-nav.component.html',
    styleUrls: ['./user-nav.component.scss'],
})
export class UserNavComponent implements OnInit {

    @Input('title') title: string;
    @Input('showFiltersOnHeader') showFiltersOnHeader = false;
    @Input('showSearchBar') showSearchBar = false;

    public searchProperty = CoffeeShopFiltersService.NAME_FILTER_PROPERTY;
    public currentSearchTerm = '';

    private filterShopsSubscription: Subscription;

    constructor(
      private events: Events,
      private coffeeShopFilters: CoffeeShopFiltersService,
      private modalCtrl: ModalController) {
    }

    ngOnInit() {
      this.filterShopsSubscription = this.coffeeShopFilters.filters
        .subscribe((filters: CoffeeShopFilter[]) => {
          const searchFilter = filters.find((f: CoffeeShopFilter) => {
            return f.filterByProperty === CoffeeShopFiltersService.NAME_FILTER_PROPERTY;
          });
          if (searchFilter) {
            this.currentSearchTerm = searchFilter.term;
          }
        });
    }

    ngOnDestroy() {
      this.filterShopsSubscription.unsubscribe();
    }

    filterCoffeeShops(filterBy, event) {
      this.coffeeShopFilters.setSearchCriteria(filterBy, event.target.value.trim());
    }

    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar;
      this.events.publish('user-nav::show-filters::show-searchbar', [true, this.showSearchBar]);
    }

    async openDeliveryMethodPicker() {
      const modal = await this.modalCtrl.create({
        component: ShopFilterModalComponent,
        backdropDismiss: false,
        cssClass: 'cm-shop-filter-modal'
      });

      modal.onDidDismiss().then(result => {
        this.coffeeShopFilters.setSearchCriteria(CoffeeShopFiltersService.DELIVERY_FILTER_PROPERTY, result.data);
      });

      return await modal.present();
    }
}
