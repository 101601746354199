import {Component, NgZone, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Storage} from '@ionic/storage';
import {ApiService} from '../../../services/api.service';
import {InAppBrowser, InAppBrowserOptions} from '@ionic-native/in-app-browser/ngx';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-gift-card-modal',
    templateUrl: './gift-card-modal.component.html',
    styleUrls: ['./gift-card-modal.component.scss'],
})
export class GiftCardModalComponent implements OnInit {

    giftCardForm: FormGroup;
    products: [];
    cards;

    constructor(private modalController: ModalController, private form: FormBuilder, private storage: Storage, private apiService: ApiService, private iab: InAppBrowser, private zone: NgZone, private nav: NavController, private events: Events) {


    }


    ngOnInit() {
        this.giftCardForm = this.form.group({
            to: [null, [Validators.required, Validators.email]],
            from: [null, [Validators.required]],
            message: [null, []],
            product: [null, [Validators.required]],
            paymentMethod: [null, [Validators.required]],
        });
        this.storage.get('user').then((user: any) => {
            this.giftCardForm.patchValue({from: user.profile.name});
        });
        this.getCards();
        this.getProducts();

    }

    getCards() {
        this.apiService.getCards(false).then((cards: any) => {
            this.cards = cards;
        });
    }

    getProducts() {
        this.apiService.getGiftCardProducts().then((products: any) => {
            this.products = products;
        });
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    checkout() {
        if (this.giftCardForm.valid) {
            console.log(this.giftCardForm.value);
            // this.giftCardForm.value
            this.apiService.buyGiftCard(this.giftCardForm.value).then((response: any) => {
                const options: InAppBrowserOptions = {
                    zoom: 'no',
                    location: 'no',
                    toolbar: 'no',
                    hideurlbar: 'yes'
                };
                const browser = this.iab.create(response.pay_url, '_blank', options);
                browser.on('loadstop').subscribe((e) => {
                    console.log(e);
                    if (e.url.indexOf('successful-order') > -1) {
                        //payment complete
                        setTimeout(() => {
                            browser.close();
                            this.zone.run(() => {
                                console.log('in zone');
                                this.closeModal();
                                this.events.publish('getOrders');
                                this.nav.navigateRoot(['/u/orders']);
                            });
                        }, 2000);
                    }

                    if (e.url.indexOf('payment-failure') > -1) {
                        //payment failure
                        setTimeout(() => {
                            browser.close();
                            this.zone.run(() => {
                                console.log('in zone');
                                this.closeModal();
                                this.events.publish('getOrders');
                                this.nav.navigateRoot(['/u/orders']);
                            });
                        }, 2000);
                    }
                });

            });
        }
    }
}
