import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {URoutingModule} from './u-routing.module';
import {UPage} from './u.page';
import {IonicModule} from '@ionic/angular';
import {ComponentsModule} from './components/components.module';

@NgModule({
  declarations: [
      UPage
  ],
  imports: [
    CommonModule,
    URoutingModule,
    IonicModule,
    ComponentsModule
  ],
  // exports:[
  //   RouterModule
  // ]
})
export class UModule { }
