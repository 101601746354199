import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TermsComponent} from './terms.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
    declarations: [TermsComponent],
    imports: [
        CommonModule,
        IonicModule
    ],
    exports: [
        TermsComponent
    ]
})
export class TermsModule {
}
