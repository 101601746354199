import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {ApiService} from '../../../services/api.service';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-cancel-modal',
    templateUrl: './cancel-modal.component.html',
    styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent implements OnInit {

    cancelForm: FormGroup;
    order: any;
    orderCancelReasons = [
        'Wont make it',
        'Wrong store',
        'Traffic',
        'Incorrect Order',
        'Other'
    ];

    constructor(private Form: FormBuilder, private modalController: ModalController, private apiService: ApiService, private alert: AlertController, private events: Events, private navCtrl: NavController) {
    }

    ngOnInit() {
        this.cancelForm = this.Form.group({
            reason: [null, [Validators.required]],
            reason_other: [null]
        });
    }


    async closeModal() {
        await this.modalController.dismiss();
    }

    cancelOrder(orderID) {
        console.log(orderID);
        let reason = this.cancelForm.controls.reason.value;

        if (reason == 'Other') {
            reason = this.cancelForm.controls.reason_other.value;
        }

        this.alert.create({
            header: 'Cancel Order Confirmation',
            message: 'Are you sure? You may not be refunded',
            buttons: [
                {
                    text: 'Yes I\'m sure',
                    handler: () => {
                        this.apiService.cancelOrder({id: orderID, reason: reason}).then((data: any) => {
                            this.events.publish('getOrders', {});
                            this.events.publish('closeOrderModal',{})
                            this.closeModal();
                        });
                    }
                }, {
                    text: 'No',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.events.publish('closeOrderModal',{})
                        this.closeModal();
                    }
                }
            ]

        }).then((al) => {
            al.present();
        });

    }

}



