import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {NavController} from '@ionic/angular';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    config = {
        u: {
            profile: 1,
            'my-favourites': 1,
            orders: 1,
            'manage-cards': 1,
            'rewards': 1,
            'address-book': 1,
            'checkout': 1,
            'gift-cards': 1,
        },
        merchant: {},
    };

    constructor(private auth: AuthService, private route: Router, private nav: NavController) {

    }

    canActivate(): boolean {
        let path1 = this.route.getCurrentNavigation().extractedUrl.root.children.primary.segments[0].path; //eg: u
        let path2 = this.route.getCurrentNavigation().extractedUrl.root.children.primary.segments[1].path; //eg: profile
        console.log(path1);
        console.log(path2);
        console.log(this.auth)
        if (this.config[path1][path2]) {
            console.log(this.auth.token)
            console.log(this.auth.isLoggedIn())
            if (this.auth.isLoggedIn()) {
                if (path2 == 'merchant') {
                    if (this.auth.isMerchant()) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            } else {
                // this.auth.showLoginModal();
                // this.nav.navigateForward('/login', {queryParams: {next: '/' + path1 + '/' + path2}});
                this.nav.navigateForward('/register', {queryParams: {next: '/' + path1 + '/' + path2}});
                return false;
            }
        } else {
            return true;
        }
    }
}
