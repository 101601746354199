import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OnResumeAppService {

  public doNotGoToOrdersOnResume = new BehaviorSubject<boolean>(false);

  doNotGoToOrders() {
    this.doNotGoToOrdersOnResume.next(true);
  }

}
