import {Injectable} from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(private firestore: AngularFirestore) {
    }


    getOrders(merchant_id) {
      let query = this.firestore.collection('cm/merchants/' + merchant_id).snapshotChanges();

        // return this.firestore.collection('cm/merchants/' + merchant_id, ref => {
        //
        //     let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        //
        //
        //     return query;
        // }).snapshotChanges();
      return query;
    }


    updateOrderStatus(merchant_id, order_id, key, value) {
        let newData = {[key]: value};
        return this.firestore.doc('cm/merchants/' + merchant_id + '/' + order_id).update(newData);
    }

    updateETA(merchant_id, order_id, key, value) {
        let newData = {[key]: value};
        return this.firestore.doc('cm/merchants/' + merchant_id + '/' + order_id).update(newData);
    }

    deleteOrder(merchant_id, order_id) {
        return this.firestore.doc('cm/merchants/' + merchant_id + '/' + order_id).delete();
    }

}
