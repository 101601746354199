export class DeliveryMethods {
  static DELIVERY_METHODS = [
    {
      key: 'Collect',
      labels: ['Collect'],
      icon: 'assets/delivery-methods/collect.png'
    },
    {
      key: 'Sit-In',
      labels: ['Sit-In'],
      icon: 'assets/delivery-methods/sit-in.png'
    },
    {
      key: 'Drop off at my Car',
      labels: ['Drop off at my Car'],
      icon: 'assets/delivery-methods/drive-through.png'
    },
    {
      key: 'Deliver to Me',
      labels: ['Deliver to Me', 'Deliver to my Office'],
      icon: 'assets/delivery-methods/deliver-to-me.png'
    }
  ];
}
