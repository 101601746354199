export class OrderStatuses {
  static ORDER_STATUSES = {
    'new': {
      shortLabel: 'Waiting for confirmation',
      longLabel: 'Waiting for confirmation'
    },
    'merchant_viewed': {
      shortLabel: 'Accepted',
      longLabel: 'Accepted'
    },
    'should_process': {
      shortLabel: 'Accepted',
      longLabel: 'Accepted'
    },
    'in_progress': {
      shortLabel: 'Preparing order',
      longLabel: 'Your order is being prepared'
    },
    'ready_for_collection': {
      shortLabel: 'Ready',
      longLabel: 'Your order is ready'
    },
    'collected': {
      shortLabel: 'Complete',
      longLabel: 'Your order is complete'
    },
    'not_collected': {
      shortLabel: 'Not collected / delivered',
      longLabel: 'Your order wasn\'t collected \/ delivered'
    },
    'merchant_cancelled': {
      shortLabel: 'Declined',
      longLabel: 'Your order was declined. You will be refunded.'
    },
    'user_cancelled': {
      shortLabel: 'Cancelled',
      longLabel: 'Your order was cancelled'
    },
    'abandoned': {
      shortLabel: 'Incomplete',
      longLabel: 'Your order is incomplete. The app was closed before payment was completed.'
    }
  };
}
