import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

    title: string;
    body: string;

    constructor(
        private apiService: ApiService,
        private modal: ModalController,
    ) {
    }

    ngOnInit() {
        this.apiService.getTerms().then((content: string) => {
            this.title = 'Terms & Conditions';
            this.body = content;
        });
    }

  dismissTerms() {
      //handled in login
    this.modal.dismiss().then(() => {

    });
  }


}
