export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyAWCgX1I1g-x0qufEdAXGKlv02YKM27gow",
        authDomain: "moco-1e7d4.firebaseapp.com",
        databaseURL: "https://moco-1e7d4.firebaseio.com",
        projectId: "moco-1e7d4",
        storageBucket: "moco-1e7d4.appspot.com",
        messagingSenderId: "738858754258",
        appId: "1:738858754258:web:76f806c1855b6789ef0d0b"
    }
};
export const CM_BASE_URL = "https://admin.coffeemonster.co.za"
export const MOCO_APISERVICE_AUTH = CM_BASE_URL + "/wp-json/jwt-auth/v1/"
export const MOCO_APISERVICE_BASE_URL = CM_BASE_URL + "/wp-json/moco/v1/"
export const MOCO_APISERVICE_BASE_URL2 = CM_BASE_URL + "/wp-json/moco/v2/"
export const MOCO_APISERVICE_URL = CM_BASE_URL + "/"
