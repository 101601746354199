import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'niceTime',
})
export class NiceTimePipe implements PipeTransform {

    constructor() {

    }

    transform(value: number) {
        if (value) {
            var response = '';

            if (value < 0) {
                // return value;
                // if (value < - (60*60*14))
                //     return 'Over a day old';

                return 'Urgent';
            }

            var saway = value;
            var hours = Math.floor(saway / 3600);
            var minutes = Math.floor((saway - (hours * 3600)) / 60);
            var seconds = Math.floor(saway - (hours * 3600) - (minutes * 60));

            if (hours > 0) {
                response += hours + ' H ';
            }
            if (minutes > 0) {
                response += minutes + ' m ';
            }
            if (seconds > 0) {
                response += seconds + ' s ';
            } else if (hours == 0 && minutes == 0 && seconds == 0) {
                response += seconds + ' s ';
            }

            if (hours >= 1) {
                // return hours;
                return 'Over a hour';
            } else {
                return response;
            }
        }
        return '';
    }
}
