import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'splash',
        pathMatch: 'full'
    },
    {
        path: 'splash',
        loadChildren: () => import('./public/splash/splash.module').then(m => m.SplashPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./public/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./public/register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./public/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
    },
    {
        path: 'merchant',
        loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule)
    },
    {
        path: 'u',
        loadChildren: () => import('./u/u.module').then(m => m.UModule)
    },
    {
        path: 'store',
        loadChildren: () => import('./u/store/store.module').then(m => m.StorePageModule)
    },
  {
    path: 'autologin',
    loadChildren: () => import('./autologin/autologin.module').then( m => m.AutologinPageModule)
  }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
