import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NiceTimePipe} from './nice-time.pipe';
import {LineItemPipe} from './line-item.pipe';

@NgModule({
    declarations: [
        NiceTimePipe,
        LineItemPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        NiceTimePipe,
        LineItemPipe
    ]
})
export class PipesModule {

}
