import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {AlertController, ModalController, NavController} from "@ionic/angular";
import {ApiService} from "../../../services/api.service";

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-review-modal',
    templateUrl: './review-modal.component.html',
    styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent implements OnInit {


    ratingForm: FormGroup;
    order: any;

    constructor(private Form: FormBuilder, private modalController: ModalController, private apiService: ApiService, private alert: AlertController, private events: Events, private navCtrl: NavController) {
    }

    ngOnInit() {
        this.ratingForm = this.Form.group({
            rating: [null,[Validators.required]],
            review: [null],
        });
    }


    rate() {
        let rating = {
            order_id: this.order.id,
            merchant_id: this.order.merchant.id,
            stars: this.ratingForm.controls.rating.value,
            comment: this.ratingForm.controls.review.value
        }

        this.apiService.rateReview(rating).then((data: any) => {
            this.alert.create({
                header: 'Thank you',
                message: 'Thank you for your rating.',
                buttons: [
                  {
                    text:'Ok',
                    handler: () => {
                      this.closeModal();
                    }
                  }
                ]

            }).then((al) => {
                al.present();
                this.events.publish('getOrders', {});
                this.events.publish('closeOrderModal',{})
            });
        }).catch((err) => {

        });
    }


    logRatingChange(e) {
        console.log(e);
    }


    async closeModal() {
        await this.modalController.dismiss(this.order.id);
    }

}
