import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {CartService} from '../../../services/cart.service';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-product-modal',
    templateUrl: './product-modal.component.html',
    styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit {

    // quantity = 1;
    // total = 0;
    // subTotal = 0;
    // cartItem = {};
    // checkboxData = {};
    // limit = 0;
    // currentlyHave = 0;
    @Input() product: any;
    @Input() category: any;
    @Input() merchant: any;


    constructor(public modalController: ModalController, navParams: NavParams, private events: Events, private alert: AlertController, public cartService: CartService) {
        this.product = navParams.get('product');
        this.category = navParams.get('category');
        this.merchant = navParams.get('merchant');
        this.cartService.load(this.product, this.category, this.merchant);
    }

    ngOnInit() {
        console.log(this.product);
    }

    closeModal() {
        this.modalController.dismiss();
    }

    plus() {
        this.cartService.plus();
    }

    minus() {
        this.cartService.minus();
    }

    addToCart() {
        if (this.cartService.validate()) {
            if (this.cartService.addToCart()){
                this.modalController.dismiss(
                    {
                        product_name: '',
                        product_description: '',
                    }
                );
            }
        }
    }

    optionChanged(e, variation) {
        this.cartService.optionChanged(e, variation);
    }

    checkChanged(e, variation, option) {
        this.cartService.checkChanged(e, variation, option);
    }

    calculateCartItem() {
        this.cartService.calculateCartItem();
    }


}
