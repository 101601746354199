import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {AlertController, ModalController} from '@ionic/angular';
import {forEach} from '@angular-devkit/schematics';
// import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import {Events} from '../services/events.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    cart;
    currentLimit = 0;
    currentlyHave = 0;
    currentProduct;
    currentCategory;
    currentSubtotal = 0;
    currentTotal = 0;
    currentQuantity = 1;
    currentCartItem = {};
    currentCheckboxData = {};
    merchant: any;

    constructor(private storage: Storage,
                private events: Events,
                private alert: AlertController,
                private modalController: ModalController
                ) {

    }

    load(product, category, merchant) {
        this.merchant = merchant;
        this.currentLimit = 0;
        this.currentlyHave = 0;
        this.currentProduct = product;
        this.currentCategory = category;
        this.currentCartItem = {};
        this.currentCheckboxData = {};
        return this.storage.get('cart').then((cart) => {
            this.cart = cart;
            if (this.cart && this.cart.length > 0) {
                this.cart.map((cartRow) => {
                    if (cartRow.category.id == this.currentCategory.id) {
                        this.currentlyHave += cartRow.quantity;
                    }
                });
            }

            if (this.currentCategory.limit) {
                this.currentLimit = this.currentCategory.limit;
            }
        });
    }

    // async saveCart() {
    //     return await this.storage.set('cart', this.cart).then(() => {
    //         return true;
    //     });
    // }

    quickAdd(product) {
        // this.firebaseAnalytics.logEvent('add_to_cart', {});
        this.currentCartItem = {};
        if (product.simple_product) {
            this.currentCartItem[product.name] = {
                name: '',
                price: product.simple_product_price
            };
        } else {
            product.variations.forEach((variation) => {
                variation.options.forEach((option) => {
                    if ((variation.single && variation.options.length == 1) || option.is_default) {
                        this.currentCartItem[variation.name] = option;
                    }
                });
            });
        }
        this.currentQuantity = 1;
        if (this.currentLimit == 0 || this.currentlyHave < this.currentLimit) {
            this.calculateCartItem();
            this.addToCart();
        } else {
            this.showLimitMessage();
        }
    }

    plus() {
        if (this.currentLimit == 0 || this.currentlyHave < this.currentLimit) {
            this.currentQuantity++;
            this.calculateCartItem();
        } else {
            this.showLimitMessage();
        }
    }

    minus() {
        if (this.currentQuantity !== 0) {
            this.currentQuantity--;
            this.calculateCartItem();
        }
    }

    showLimitMessage() {
        let extraMessage = '';
        if (this.currentlyHave > 0) {
            extraMessage = 'You currently have ' + this.currentlyHave + ' in your basket';
        }

        this.alert.create({
            header: 'Limit reached',
            message: 'This product category has a limit of ' + this.currentLimit + ' items per order. ' + extraMessage,
            buttons: ['OK']
        }).then((al) => {
            al.present();
        });
    }

    optionChanged(e, variation) {
        this.currentCartItem[variation.name] = e.target.value;
        this.calculateCartItem();
    }

    checkChanged(e, variation, option) {
        if (!this.currentCheckboxData[variation.name]) {
            this.currentCheckboxData[variation.name] = [];
        }
        let wasChecked = e.target.checked;
        if (wasChecked == false) {
            //add it
            this.currentCheckboxData[variation.name].push(option);
        } else {
            //remove it
            let newData = [];
            this.currentCheckboxData[variation.name].map((row) => {
                if (row.name !== option.name) {
                    newData.push(row);
                }
            });
            this.currentCheckboxData[variation.name] = newData;

        }
        this.calculateCartItem();
    }

    showError(errorMsg) {
        this.alert.create({
            header: 'Error',
            message: errorMsg,
            buttons: ['OK']
        }).then((al) => {
            al.present();
        });
        return false;
    }

    validate() {
        if (this.currentQuantity == 0) {
            return this.showError('Please select a Quantity')
        }
        if (this.currentProduct.simple_product) {
            return true;
        }

        let countRadios = 0
        this.currentProduct.variations.forEach((variation)=>{
            if (variation.single){
                countRadios++
            }
        })

        if (Object.keys(this.currentCartItem).length !== countRadios){
            return this.showError('Please select the required options')
        }

        return true;
    }

    addToCart() {
        // this.firebaseAnalytics.logEvent('add_to_cart', {});
        if (this.currentLimit == 0 || this.currentlyHave > this.currentLimit) {
            this.showLimitMessage();
            return false;
        }

        let productData = {};
        productData['options'] = {};

        Object.keys(this.currentCartItem).forEach((key) => {
            productData['options'][key] = this.currentCartItem[key];
        });
        Object.keys(this.currentCheckboxData).forEach((key) => {
            productData['options'][key] = [];
            this.currentCheckboxData[key].map((row) => {
                productData['options'][key].push(row);
            });
        });

        productData['quantity'] = this.currentQuantity;
        productData['product'] = this.currentProduct;
        productData['category'] = this.currentCategory;
        productData['subTotal'] = this.currentSubtotal;
        productData['total'] = this.currentTotal;
        productData['merchant'] = this.merchant;


        this.events.publish('addToCart', productData);
        return true;

    }

    calculateCartItem() {
        this.currentTotal = 0;
        Object.keys(this.currentCartItem).forEach((key) => {
            this.currentTotal += parseFloat(this.currentCartItem[key].price);
        });

        Object.keys(this.currentCheckboxData).forEach((key) => {
            Object.keys(this.currentCheckboxData[key]).forEach((optionName) => {
                if (this.currentCheckboxData[key][optionName] !== false) {
                    this.currentTotal += parseFloat(this.currentCheckboxData[key][optionName].price);
                }
            });
        });
        this.currentSubtotal = this.currentTotal;
        this.currentTotal = this.currentTotal * this.currentQuantity;
    }
}
