import {Component, OnInit} from '@angular/core';
import { ModalController } from "@ionic/angular";
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { DeliveryMethods } from '../../../common/delivery-methods';
import { CoffeeShopFiltersService, CoffeeShopFilter } from '../../../services/coffee-shop-filters.service';

@Component({
    selector: 'app-shop-filter-modal',
    templateUrl: './shop-filter-modal.component.html',
    styleUrls: ['./shop-filter-modal.component.scss'],
})
export class ShopFilterModalComponent implements OnInit {

  deliveryMethodsData = DeliveryMethods.DELIVERY_METHODS;
  form: FormGroup;

  constructor(private modalController: ModalController,
              private formBuilder: FormBuilder,
              private coffeeShopFilters: CoffeeShopFiltersService) {
    this.form = this.formBuilder.group({
      deliveryMethods: new FormArray([])
    });
    this.addFormControls();
  }

  ngOnInit() {
  }

  get deliveryMethodsFormArray() {
    return this.form.controls.deliveryMethods as FormArray;
  }

  private addFormControls() {
    const currentFilters = this.coffeeShopFilters.filters.value;
    const currentDeliveryFilter = currentFilters.find((f: CoffeeShopFilter) => {
      return f.filterByProperty === CoffeeShopFiltersService.DELIVERY_FILTER_PROPERTY;
    });
    this.deliveryMethodsData.forEach((method) => {
      if (currentDeliveryFilter) {
        const checked = currentDeliveryFilter.term.includes(method.key);
        this.deliveryMethodsFormArray.push(new FormControl(checked));
      } else {
        this.deliveryMethodsFormArray.push(new FormControl(false));
      }
    });
  }

  async closeModal(selectedMethods) {
    const selectedMethodsIds = this.form.value.deliveryMethods
      .map((checked, i) => {
        return checked ? this.deliveryMethodsData[i].key : null
      })
      .filter(item => {
        return item !== null
      });
    await this.modalController.dismiss(selectedMethodsIds);
  }
}
