import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'lineItem',
})
export class LineItemPipe implements PipeTransform {

    constructor() {

    }

    transform(lineItems: any) {
        let niceString = [];
        lineItems.forEach((item) => {
            if (item.key !== 'has_loyalty') {
                // niceString.push(item.key + ' : ' + item.value);
                niceString.push(item.value);
            }

        });
        // return niceString.join('<br/>');
        return niceString.join(' ');
    }
}
