import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserNavComponent} from './user-nav/user-nav.component';
import {IonicModule} from '@ionic/angular';
import {OrderModalComponent} from './order-modal/order-modal.component';
import {ReviewModalComponent} from './review-modal/review-modal.component';
import {ProductModalComponent} from './product-modal/product-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {StarRatingModule} from 'ionic5-star-rating';
import {CancelModalComponent} from "./cancel-modal/cancel-modal.component";
import {CheckoutBarComponent} from './checkout-bar/checkout-bar.component';
import {AddressModalComponent} from './address-modal/address-modal.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {PipesModule} from '../../pipes/pipes.module';
import {GiftCardModalComponent} from './gift-card-modal/gift-card-modal.component';
import {ShopFilterModalComponent} from './shop-filter-modal/shop-filter-modal.component';

@NgModule({
    entryComponents: [
        UserNavComponent,
        OrderModalComponent,
        ReviewModalComponent,
        ProductModalComponent,
        CancelModalComponent,
        CheckoutBarComponent,
        AddressModalComponent,
        GiftCardModalComponent,
        ShopFilterModalComponent
    ],
    declarations: [
        UserNavComponent,
        OrderModalComponent,
        ReviewModalComponent,
        ProductModalComponent,
        CancelModalComponent,
        CheckoutBarComponent,
        AddressModalComponent,
        GiftCardModalComponent,
        ShopFilterModalComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        // StarRatingModule,
        FormsModule,
        GooglePlaceModule,
        PipesModule,
    ],
    exports: [
        UserNavComponent,
        OrderModalComponent,
        ReviewModalComponent,
        ProductModalComponent,
        CancelModalComponent,
        CheckoutBarComponent,
        AddressModalComponent,
        GiftCardModalComponent,
        ShopFilterModalComponent
    ]
})
export class ComponentsModule {
}
