import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {ApiService} from '../../../services/api.service';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';

@Component({
    selector: 'app-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {

    @ViewChild('addressRef', {read: false, static: false}) addressRef: GooglePlaceDirective;
    addressText: string;
    addressForm: FormGroup;
    isAdhoc: boolean = false;

    constructor(
        private form: FormBuilder,
        private modalController: ModalController,
        private apiService: ApiService,
        private navParams: NavParams
    ) {

    }

    ngOnInit() {

        if (this.navParams.get('isAdhoc') == true) {
            this.isAdhoc = true;
            this.addressForm = this.form.group({
                location: [null, [Validators.required]],
                address: [null, [Validators.required]],
            });
        } else {
            this.addressForm = this.form.group({
                name: [null, [Validators.required]],
                location: [null, [Validators.required]],
                address: [null, []],
            });
        }

    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    submit() {
        console.log(this.addressForm.value);
        if (this.isAdhoc) {
            this.modalController.dismiss(this.addressForm.value);
        } else {
            this.apiService.addAddress(this.addressForm.value).then((data: any) => {
                this.modalController.dismiss(data);
            });
        }
    }

    addressChange(e) {
        this.addressForm.patchValue({
            location: {
                address: e.formatted_address,
                lat: e.geometry.location.lat(),
                lng: e.geometry.location.lng()
            },
        });
        this.addressText = e.formatted_address;
    }

}
